@import url("https://fonts.googleapis.com/css?family=Aclonica&display=swap");
@import url("https://fonts.googleapis.com/css?family=Comfortaa:400&display=swap");
.sidebar {
  height: 100vh;
  /* margin-top: -50px; */
  width: 70%;
  background-color: white;
  overflow-y: none;
  text-align: center;
}

link {
  text-align: left;
}

.sidebar>h1 {
  padding-top: 20px;
  text-align: center;
}

.h1_links {
  text-decoration: none;
  color: #000;
  font-size: 32px;
}

.sidebar>p {
  margin-top: 30px;
  text-align: center;
}

.sidebar-nav {
  margin-top: -34px;
  text-align: left;
}

.sidebar>img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 40px;
}

.links {
  text-decoration: none;
  /* font-family: 'Quicksand', sans-serif; */
  font-family: "Comfortaa";
  color: #000;
  font-size: 10px;
  line-height: 4px;
  text-align: left;
  text-transform: uppercase;
}

/* when selected     font-style: oblique;
font-weight: 600 */

.sidebar-nav-icons {
  margin-bottom: 15px;
}

.flip-card-back {
  margin-top: 50px;
}

.fa-lg {
  text-decoration: none;
}

.links:hover {
  color: gray;
  text-decoration: none;
}

.fas:hover {
  text-decoration: none;
}

ul {
  list-style: none;
}

.h1_links:hover {
  color: #000;
}

/*//////////////media ////////////////////*/

@media (max-width: 980px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 29px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 28px;
  }
  .fas {
    transform: scale(1.3);
    margin: 5px 0px;
  }
  .gmail {
    font-size: 16px;
    font-weight: normal;
  }
}

@media (max-width: 370px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 24px;
  }
  .links {
    font-size: 16px;
  }
  .fas {
    transform: scale(1.1);
    margin: 5px 0px;
  }
}