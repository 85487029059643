@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Aclonica&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
body {
  margin: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.App {
  height: 710px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  font-family: "Quicksand", Arial, sans-serif;
  line-height: 1.3em;
  letter-spacing: 0.04em;
}

.side {
  width: 300px;
}

.main {
  overflow: scroll;
  overflow-x: hidden;
}

button {
  border: none !important;
  outline: none !important;
}

p {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
}

/*//////////////// Media Query/////////////////*/

@media (max-width: 992px) {
  .App {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  h2 {
    font-weight: bold;
  }
  p {
    font-weight: bolder;
  }
  .main {
    margin-top: -40px;
    overflow-y: hidden;
  }
}

footer {
  position: absolute;
  width: 100%;
  bottom: 20px;
}

p.copyright,
span.copyright {
  position: absolute;
  width: 100%;
  color: black;
  line-height: 40px;
  font-size: 0.7em;
  bottom: 10px;
  left: 40px;
}

@media (max-width: 769px) {
  .App {
    display: flex;
    flex-direction: column;
  }
  .main {
    overflow: auto;
    overflow-x: hidden;
  }
  .navbar-toggler {
    margin-left: 150px;
    margin-top: 4px;
  }

  .side {
    margin: 12px 24px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }
  footer {
    position: absolute;
    bottom: 200px;
    margin-left: -34px;
  }
  .sidebar-nav {
    margin-top: -34px;
    text-align: left;
    margin-left: -38px;
  }
}
@media (max-width: 1024px) {
  .ipad {
    width: 95%;
  }
}

.About_box__2iRF7 {
  margin-top: 40px;
}

.About_About__1dBX1 {
  padding: 42px 42px 42px 0px;
  display: flex;
  justify-content: space-between;
  margin-top: 7%;
}

.About_br__5D48f {
  margin-top: 20px;
}

.About_image__2meN6 {
  height: 40vh;
}

.About_head__3DYrU {
  display: inline-block;
  padding: 0px 12px;
  color: rgb(153, 153, 153);
  margin-bottom: 20px;
  margin-left: 30px;
  letter-spacing: 5px;
  font-size: 11px;
}

.About_heading__1WzB7 {
  padding: 0px 12px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
}

.About_label__3MYou {
  position: relative;
  background: #f2f3f7;
  padding: 1.5em;
  margin-left: 60px;
}

.About_label__3MYou h2 {
  font-size: 20px;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: 500;
}

.About_para__2XUz7 {
  text-align: justify;
  text-justify: inter-word;
  padding: 0px 54px;
  font-size: 12px;
  margin-left: -24px;
  font-weight: 700;
}

.About_label__3MYou p {
  margin-left: 200px;
}

@-webkit-keyframes About_text__3d2ki {
  from {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    opacity: 0.5;
  }
}

@keyframes About_text__3d2ki {
  from {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    opacity: 0.5;
  }
}

@media (max-width: 992px) {
  .About_box__2iRF7 {
    text-align: center;
    margin: 26px;
  }
  .About_About__1dBX1 {
    padding: unset;
    display: unset;
    margin-top: 7%;
  }
  .About_image__2meN6 {
    margin: 20px;
    height: 40vh;
    width: 90%;
    object-fit: contain;
  }
  .About_para__2XUz7 {
    padding: 0px 24px;
    margin-top: -42px;
  }
}

.Interest_box__1QXKR {
  margin: 15px;
  margin-top: 124px;
  /* box-shadow: 10px 10px 20px rgba(244,241,241,1) ; */
  border-radius: 6px;
  /* animation-name: text;
    animation-duration: 7s;
    animation-timing-function: ease-out; */
}

.Interest_Interest__1nrKb {
  /* background-color:rgba(220, 220,220, 0.3); */
  border-radius: 3px;
  margin-left: 20px;
  margin-top: 45px;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}

.Interest_head__1v98p {
  display: inline-block;
  padding: 0px 12px;
  color: rgb(153, 153, 153);
  margin-bottom: 20px;
  margin-left: 30px;
  letter-spacing: 5px;
  font-size: 11px;
}

.Interest_heading__XTPzc {
  padding: 0px 12px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
}

div > h3 {
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 20px 0 30px 0;
}

div > p {
  font-size: 15px;
}

.Interest_web__34oC7 {
  height: 100%;
  /* height:180px; */
  border: 1px black;
  border-bottom: 2px solid #2c98f0;
  text-align: center;
  padding: 1.2rem;
  box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
}

.Interest_app__bEJ73 {
  /* height:180px; */
  height: 100%;
  border: 1px black;
  border-bottom: 2px solid #f9bf3f;
  text-align: center;
  box-shadow: 10px 10px 20px rgba(244, 241, 241, 1);
  position: relative;
  top: -20px;
  padding: 1.2rem;
}

.Interest_other__2P7kx {
  height: 100%;
  /* height:180px; */
  border: 1px black;
  padding: 1.2rem;
  border-bottom: 2px solid #2fa499;
  text-align: center;
  box-shadow: 10px 10px 20px rgba(244, 241, 241, 1);
}

@-webkit-keyframes Interest_text__1kD-J {
  from {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    opacity: 0;
  }
}

@keyframes Interest_text__1kD-J {
  from {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    opacity: 0;
  }
}

/*///////// Media Query //////////////*/

@media (max-width: 1027px) {
  .Interest_Interest__1nrKb {
    grid-template-columns: 1fr;
  }
  .Interest_web__34oC7 {
    margin-bottom: 25px;
  }
  .Interest_app__bEJ73 {
    margin-bottom: 25px;
  }
  .Interest_other__2P7kx {
    margin-bottom: 25px;
  }
}

@media (max-width: 769px) {
  .Interest_box__1QXKR {
    text-align: center;
  }
  .Interest_Interest__1nrKb {
    grid-template-columns: 1fr;
  }
  .Interest_app__bEJ73 {
    margin-top: 20px;
    height: 100%;
    /* position: static; */
  }
  .Interest_other__2P7kx {
    margin-top: 20px;
    height: 180px;
  }
}

@media (max-width: 392px) {
  .Interest_Interest__1nrKb {
    grid-template-columns: 1fr;
  }
  .Interest_app__bEJ73 {
    position: static;
  }
}

.CarouselImages_carousel__1nHR- {
  margin: auto;
  padding: 124px 0px;
}

.CarouselImages_image_container__-pi0m {
  width: unset !important;
  display: flex;
  justify-content: space-between;
  background-color: white !important;
}

.CarouselImages_image__2vh2r {
  height: 500px;
  object-fit: contain;
}

.CarouselImages_para__sXI8k {
  text-align: justify;
  text-justify: inter-word;
  width: 50%;
  font-size: 12px;
}

.CarouselImages_para2__1liav {
  text-align: justify;
  text-justify: inter-word;
  width: 100%;
  font-size: 12px;
}

.CarouselImages_para1__w8hEE {
  text-align: right;
  font-weight: bold;
  font-style: oblique;
  text-justify: inter-word;
  width: 100%;
  font-size: 12px;
}

@media (max-width: 768px) {
  .CarouselImages_image__2vh2r {
    height: 400px;
  }
  .CarouselImages_carousel__1nHR- {
    margin: auto;
    padding: 0px;
    height: 80vh;
    width: 100vw;
  }
  .CarouselImages_image_container__-pi0m {
    margin: 40px !important;
  }
  Slider {
    height: 70vh !important;
  }
  .CarouselImages_flex__2_MkU {
    display: unset;
  }
  .CarouselImages_para__sXI8k {
    width: 100%;
  }
  .CarouselImages_arrows__2kk5Y {
    margin: 0px 42px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .CarouselImages_image__2vh2r {
    height: 800px;
    padding: 40px;
  }
}

@media (min-width: 1600px) {
  .CarouselImages_image__2vh2r {
    height: 50vh !important;
  }
}
.CarouselImages_carousel__3KGPV {
  margin: auto;
  padding: 124px 0px;
}

.CarouselImages_image_container__FGjwL {
  width: unset !important;
  display: flex;
  justify-content: space-between;
  background-color: white !important;
}

.CarouselImages_para__3Js-s {
  text-align: justify;
  text-justify: inter-word;
  width: 50%;
  font-size: 12px;
}

.CarouselImages_image__1LwBU {
  height: 500px;
  max-width: 95%;
  object-fit: contain;
}

@-webkit-keyframes CarouselImages_text__EvVQz {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@keyframes CarouselImages_text__EvVQz {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .CarouselImages_image__1LwBU {
    height: 400px;
  }

  .CarouselImages_carousel__3KGPV {
    margin: auto;
    padding: 0px;
    height: 80vh;
    width: 100vw;
  }

  .CarouselImages_image_container__FGjwL {
    margin: 40px !important;
  }

  Slider {
    height: 70vh !important;
  }

  .CarouselImages_flex__2VQpy {
    display: unset;
  }

  .CarouselImages_para__3Js-s {
    width: 100%;
  }

  .CarouselImages_arrows__2WRXF {
    margin: 0px 42px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .CarouselImages_image__1LwBU {
    height: 800px;
    padding: 40px;
  }
}

@media (min-width: 1600px) {
  .CarouselImages_image__1LwBU {
    height: 50vh !important;
  }
}
.CarouselImages_carousel__1zorn {
  margin: auto;
  padding: 124px 0px;
}

.CarouselImages_image_container__3r68T {
  width: unset !important;
  display: flex;
  justify-content: space-between;
  background-color: white !important;
}

.CarouselImages_image_container1__38JeH {
  width: unset !important;
  display: flex;
  justify-content: space-between;
  background-color: white !important;
}

.CarouselImages_image__2gyeY {
  height: 400px;
  object-fit: contain;
}

.CarouselImages_para__1ixa8 {
  text-align: justify;
  text-justify: inter-word;
  width: 50%;
  font-size: 12px;
}

@-webkit-keyframes CarouselImages_text__mTX2f {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@keyframes CarouselImages_text__mTX2f {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

.CarouselImages_flex__NK2oS {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .CarouselImages_image__2gyeY {
    height: 400px;
  }

  .CarouselImages_carousel__1zorn {
    margin: auto;
    padding: 0px;
    height: 80vh;
    width: 100vw;
  }

  .CarouselImages_image_container__3r68T {
    margin: 46px !important;
  }

  .CarouselImages_image_container1__38JeH {
    margin: 46px !important;
    height: 70vh !important;
    overflow-y: scroll;
  }

  .CarouselImages_inner__KaDHR {
    height: 70vh !important;
    overflow: hidden;
  }

  .CarouselImages_flex__NK2oS {
    display: unset;
  }

  .CarouselImages_para__1ixa8 {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 100px;
  }

  .CarouselImages_arrows__AUe7J {
    margin: 0px 42px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .CarouselImages_image__2gyeY {
    height: 800px;
    padding: 40px;
  }

  .CarouselImages_para__1ixa8 {
    width: 100%;
    padding: 0px 100px;
    overflow-y: auto;
    margin-bottom: 100px;
  }

  .CarouselImages_heading__2BWnf {
    width: 100%;
    padding: 0px 100px;
  }
}

@media (min-width: 1600px) {
  .CarouselImages_image__2gyeY {
    height: 50vh !important;
  }
}
.gallery-container {
  padding: 84px 0;
  margin: 26px 0px;
  overflow: none;
}

.gallery-grid {
  display: grid;
  /* grid-template-columns: 0.4fr 0.5fr 0.1fr 0.6fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  margin: 20px; */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-flow: dense;
  list-style: none;
  margin-right: 2em;
  margin-left: -1em;
  padding: 0;
}

/* .gallery-grid > div {
  border: 1px solid black;
  height: 100px;
} */

.one {
  padding: 28px 64px 0px 120px;
}

.second {
  padding: 20px 12px 0px 81px;
}

.third {
  padding: 20px 12px 0px 90px;
}

.fourth {
  padding: 46px 75px 0px 70px;
}

.fifth {
  padding: 38px 166px 0px 10px;
}

.six {
  padding: 10px 42px 10px 90px;
}

.seven {
  padding: 15px 42px 0px 40px;
}

.eight {
  padding: 70px 10px 0px 50px;
}

.ten {
  padding: 36px 40px 0px 80px;
  margin-top: -20px;
}

.eleven {
  padding: 10px 160px 0px 16px;
}

.twelve {
  padding: 24px 120px 0px 10px;
}

.thirteen {
  padding: 0px 12px 0px 81px;
}

.fourteen {
  padding: 20px 75px 0px 70px;
}

.video1 {
  padding: 20px 2px 0px 4px;
}

.video2 {
  padding: 22px 0px 0px 22px;
}

video::-webkit-media-controls-panel {
  background-image: none !important;
}

.img {
  position: relative;
  cursor: pointer;
}

img {
  width: 100%;
  object-fit: cover;
}

.modal {
  position: fixed;
  z-index: 999;
  width: 50%;
  max-width: 800px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}

.modal-overlay {
  position: fixed;
  margin: 0 auto;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-body {
  position: relative;
  margin: auto;
  height: 70%;
  width: 70%;
}

.modal-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .gallery-container {
    padding: unset;
    margin: 26px 0px;
    overflow: none;
  }

  .gallery-grid {
    grid-gap: 48px;
    margin-left: 100px;
  }

  #gallery img {
    width: 200px !important;
    height: 200px !important;
    object-fit: contain;
  }

  video {
    width: 200px !important;
    height: 200px !important;
  }

  .one {
    padding: unset;
  }

  .second {
    padding: unset;
  }

  .third {
    padding: unset;
  }

  .fourth {
    padding: unset;
  }

  .fifth {
    padding: unset;
  }

  .six {
    padding: unset;
  }

  .seven {
    padding: unset;
  }

  .eight {
    padding: unset;
  }

  .ten {
    padding: unset;
    margin-top: -20px;
  }

  .eleven {
    padding: unset;
  }

  .twelve {
    padding: unset;
  }

  .thirteen {
    padding: unset;
  }

  .fourteen {
    padding: unset;
  }

  .one img {
    width: 300px !important;
    height: 300px !important;
  }

  .second img {
    width: 300px !important;
    height: 300px !important;
  }

  .third img {
    width: 300px !important;
    height: 300px !important;
  }

  .fourthimg {
    width: 300px !important;
    height: 300px !important;
  }

  .fifth img {
    width: 300px !important;
    height: 300px !important;
  }

  .six img {
    width: 300px !important;
    height: 300px !important;
  }

  .sevenimg {
    width: 300px !important;
    height: 300px !important;
  }

  .eight img {
    padding: unset;
  }

  .ten img {
    width: 300px !important;
    height: 300px !important;
  }

  .eleven img {
    width: 300px !important;
    height: 300px !important;
  }

  .twelve img {
    width: 300px !important;
    height: 300px !important;
  }

  .thirteen img {
    width: 300px !important;
    height: 300px !important;
  }

  .fourteen img {
    width: 300px !important;
    height: 300px !important;
  }

  .video1 {
    padding: unset;
  }

  .video2 {
    padding: unset;
  }
}
.CarouselImages_carousel__1l7j9 {
  margin: auto;
  padding: 124px 0px;
}

.CarouselImages_image_container__3SSjm {
  width: unset !important;
  display: flex;
  justify-content: space-between;
  background-color: white !important;
}

.CarouselImages_para__2kD0d {
  text-align: justify;
  text-justify: inter-word;
  width: 50%;
  font-size: 12px;
}

.CarouselImages_image__JIL8h {
  height: 500px;
  max-width: 95%;
  object-fit: contain;
}

@-webkit-keyframes CarouselImages_text__2aWpE {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@keyframes CarouselImages_text__2aWpE {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .CarouselImages_image__JIL8h {
    height: 400px;
  }

  .CarouselImages_carousel__1l7j9 {
    margin: auto;
    padding: 0px;
    height: 80vh;
    width: 100vw;
  }

  .CarouselImages_image_container__3SSjm {
    margin: 40px !important;
  }

  Slider {
    height: 70vh !important;
  }

  .CarouselImages_flex__33DwT {
    display: unset;
  }

  .CarouselImages_para__2kD0d {
    width: 100%;
  }

  .CarouselImages_arrows__3cnPQ {
    margin: 0px 42px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .CarouselImages_image__JIL8h {
    height: 800px;
    padding: 40px;
  }
}

@media (min-width: 1600px) {
  .CarouselImages_image__JIL8h {
    height: 50vh !important;
  }
}
.Home_box__2rw9d {
  margin: 124px 100px 0px 0px;
  overflow: hidden;
  height: 80vh;
}

.Home_About__rB39O {
  margin-left: 0px;
  padding: 20px 0px;
}

.Home_image__1SefA {
  object-fit: contain;
}

.Home_br__2OLpL {
  margin-top: 20px;
}

.Home_head__2z6k7 {
  display: inline-block;
  padding: 0px 12px;
  color: rgb(153, 153, 153);
  margin-bottom: 20px;
  margin-left: 30px;
  letter-spacing: 5px;
  font-size: 11px;
}

.Home_heading__2QiQs {
  padding: 0px 12px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
}

.Home_label__2o0vL {
  position: relative;
  background: #f2f3f7;
  padding: 1.5em;
  margin-left: 60px;
}

.Home_label__2o0vL h2 {
  font-size: 20px;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: 500;
}

.Home_label__2o0vL p {
  margin-left: 200px;
}

@-webkit-keyframes Home_text__22ldf {
  from {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    opacity: 0.5;
  }
}

@keyframes Home_text__22ldf {
  from {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    opacity: 0.5;
  }
}

@media (max-width: 768px) {
  .Home_box__2rw9d {
    text-align: center;
    display: flex;
    margin: 0 44px;
  }
  img {
    width: 100%;
  }
}

@media (max-width: 1024px) {
}

.sidebar {
  height: 100vh;
  /* margin-top: -50px; */
  width: 70%;
  background-color: white;
  overflow-y: none;
  text-align: center;
}

link {
  text-align: left;
}

.sidebar>h1 {
  padding-top: 20px;
  text-align: center;
}

.h1_links {
  text-decoration: none;
  color: #000;
  font-size: 32px;
}

.sidebar>p {
  margin-top: 30px;
  text-align: center;
}

.sidebar-nav {
  margin-top: -34px;
  text-align: left;
}

.sidebar>img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 40px;
}

.links {
  text-decoration: none;
  /* font-family: 'Quicksand', sans-serif; */
  font-family: "Comfortaa";
  color: #000;
  font-size: 10px;
  line-height: 4px;
  text-align: left;
  text-transform: uppercase;
}

/* when selected     font-style: oblique;
font-weight: 600 */

.sidebar-nav-icons {
  margin-bottom: 15px;
}

.flip-card-back {
  margin-top: 50px;
}

.fa-lg {
  text-decoration: none;
}

.links:hover {
  color: gray;
  text-decoration: none;
}

.fas:hover {
  text-decoration: none;
}

ul {
  list-style: none;
}

.h1_links:hover {
  color: #000;
}

/*//////////////media ////////////////////*/

@media (max-width: 980px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 29px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 28px;
  }
  .fas {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    margin: 5px 0px;
  }
  .gmail {
    font-size: 16px;
    font-weight: normal;
  }
}

@media (max-width: 370px) {
  .sidebar {
    width: 106vw;
  }
  .h1_links {
    font-size: 24px;
  }
  .links {
    font-size: 16px;
  }
  .fas {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    margin: 5px 0px;
  }
}
.CarouselImages_carousel__3pnVj {
  margin: auto;
  padding: 124px 0px;
}

.CarouselImages_image_container__3kajA {
  width: unset !important;
  display: flex;
  justify-content: space-between;
  background-color: white !important;
}

.CarouselImages_para__6ZkuG {
  text-align: justify;
  text-justify: inter-word;
  width: 50%;
  font-size: 12px;
}

.CarouselImages_image__2ZPdr {
  height: 500px;
  max-width: 95%;
  object-fit: contain;
}

@-webkit-keyframes CarouselImages_text__3re_e {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@keyframes CarouselImages_text__3re_e {
  from {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .CarouselImages_image__2ZPdr {
    height: 400px;
  }

  .CarouselImages_carousel__3pnVj {
    margin: auto;
    padding: 0px;
    height: 80vh;
    width: 100vw;
  }

  .CarouselImages_image_container__3kajA {
    margin: 40px !important;
  }

  Slider {
    height: 70vh !important;
  }

  .CarouselImages_flex__1hGHl {
    display: unset;
  }

  .CarouselImages_para__6ZkuG {
    width: 100%;
  }

  .CarouselImages_arrows__11lEO {
    margin: 0px 42px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .CarouselImages_image__2ZPdr {
    height: 800px;
    padding: 40px;
  }
}

@media (min-width: 1600px) {
  .CarouselImages_image__2ZPdr {
    height: 50vh !important;
  }
}
