.box {
  margin-top: 40px;
}

.About {
  padding: 42px 42px 42px 0px;
  display: flex;
  justify-content: space-between;
  margin-top: 7%;
}

.br {
  margin-top: 20px;
}

.image {
  height: 40vh;
}

.head {
  display: inline-block;
  padding: 0px 12px;
  color: rgb(153, 153, 153);
  margin-bottom: 20px;
  margin-left: 30px;
  letter-spacing: 5px;
  font-size: 11px;
}

.heading {
  padding: 0px 12px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
}

.label {
  position: relative;
  background: #f2f3f7;
  padding: 1.5em;
  margin-left: 60px;
}

.label h2 {
  font-size: 20px;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: 500;
}

.para {
  text-align: justify;
  text-justify: inter-word;
  padding: 0px 54px;
  font-size: 12px;
  margin-left: -24px;
  font-weight: 700;
}

.label p {
  margin-left: 200px;
}

@keyframes text {
  from {
    transform: translateX(-30px);
    opacity: 0.5;
  }
}

@media (max-width: 992px) {
  .box {
    text-align: center;
    margin: 26px;
  }
  .About {
    padding: unset;
    display: unset;
    margin-top: 7%;
  }
  .image {
    margin: 20px;
    height: 40vh;
    width: 90%;
    object-fit: contain;
  }
  .para {
    padding: 0px 24px;
    margin-top: -42px;
  }
}
