.box {
  margin: 124px 100px 0px 0px;
  overflow: hidden;
  height: 80vh;
}

.About {
  margin-left: 0px;
  padding: 20px 0px;
}

.image {
  object-fit: contain;
}

.br {
  margin-top: 20px;
}

.head {
  display: inline-block;
  padding: 0px 12px;
  color: rgb(153, 153, 153);
  margin-bottom: 20px;
  margin-left: 30px;
  letter-spacing: 5px;
  font-size: 11px;
}

.heading {
  padding: 0px 12px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
}

.label {
  position: relative;
  background: #f2f3f7;
  padding: 1.5em;
  margin-left: 60px;
}

.label h2 {
  font-size: 20px;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: 500;
}

.label p {
  margin-left: 200px;
}

@keyframes text {
  from {
    transform: translateX(-30px);
    opacity: 0.5;
  }
}

@media (max-width: 768px) {
  .box {
    text-align: center;
    display: flex;
    margin: 0 44px;
  }
  img {
    width: 100%;
  }
}

@media (max-width: 1024px) {
}
