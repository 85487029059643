@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
.App {
  height: 710px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  font-family: "Quicksand", Arial, sans-serif;
  line-height: 1.3em;
  letter-spacing: 0.04em;
}

.side {
  width: 300px;
}

.main {
  overflow: scroll;
  overflow-x: hidden;
}

button {
  border: none !important;
  outline: none !important;
}

p {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
}

/*//////////////// Media Query/////////////////*/

@media (max-width: 992px) {
  .App {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  h2 {
    font-weight: bold;
  }
  p {
    font-weight: bolder;
  }
  .main {
    margin-top: -40px;
    overflow-y: hidden;
  }
}

footer {
  position: absolute;
  width: 100%;
  bottom: 20px;
}

p.copyright,
span.copyright {
  position: absolute;
  width: 100%;
  color: black;
  line-height: 40px;
  font-size: 0.7em;
  bottom: 10px;
  left: 40px;
}

@media (max-width: 769px) {
  .App {
    display: flex;
    flex-direction: column;
  }
  .main {
    overflow: auto;
    overflow-x: hidden;
  }
  .navbar-toggler {
    margin-left: 150px;
    margin-top: 4px;
  }

  .side {
    margin: 12px 24px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }
  footer {
    position: absolute;
    bottom: 200px;
    margin-left: -34px;
  }
  .sidebar-nav {
    margin-top: -34px;
    text-align: left;
    margin-left: -38px;
  }
}
@media (max-width: 1024px) {
  .ipad {
    width: 95%;
  }
}
