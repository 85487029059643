.gallery-container {
  padding: 84px 0;
  margin: 26px 0px;
  overflow: none;
}

.gallery-grid {
  display: grid;
  /* grid-template-columns: 0.4fr 0.5fr 0.1fr 0.6fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  margin: 20px; */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-flow: dense;
  list-style: none;
  margin-right: 2em;
  margin-left: -1em;
  padding: 0;
}

/* .gallery-grid > div {
  border: 1px solid black;
  height: 100px;
} */

.one {
  padding: 28px 64px 0px 120px;
}

.second {
  padding: 20px 12px 0px 81px;
}

.third {
  padding: 20px 12px 0px 90px;
}

.fourth {
  padding: 46px 75px 0px 70px;
}

.fifth {
  padding: 38px 166px 0px 10px;
}

.six {
  padding: 10px 42px 10px 90px;
}

.seven {
  padding: 15px 42px 0px 40px;
}

.eight {
  padding: 70px 10px 0px 50px;
}

.ten {
  padding: 36px 40px 0px 80px;
  margin-top: -20px;
}

.eleven {
  padding: 10px 160px 0px 16px;
}

.twelve {
  padding: 24px 120px 0px 10px;
}

.thirteen {
  padding: 0px 12px 0px 81px;
}

.fourteen {
  padding: 20px 75px 0px 70px;
}

.video1 {
  padding: 20px 2px 0px 4px;
}

.video2 {
  padding: 22px 0px 0px 22px;
}

video::-webkit-media-controls-panel {
  background-image: none !important;
}

.img {
  position: relative;
  cursor: pointer;
}

img {
  width: 100%;
  object-fit: cover;
}

.modal {
  position: fixed;
  z-index: 999;
  width: 50%;
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.modal-overlay {
  position: fixed;
  margin: 0 auto;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-body {
  position: relative;
  margin: auto;
  height: 70%;
  width: 70%;
}

.modal-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .gallery-container {
    padding: unset;
    margin: 26px 0px;
    overflow: none;
  }

  .gallery-grid {
    grid-gap: 48px;
    margin-left: 100px;
  }

  #gallery img {
    width: 200px !important;
    height: 200px !important;
    object-fit: contain;
  }

  video {
    width: 200px !important;
    height: 200px !important;
  }

  .one {
    padding: unset;
  }

  .second {
    padding: unset;
  }

  .third {
    padding: unset;
  }

  .fourth {
    padding: unset;
  }

  .fifth {
    padding: unset;
  }

  .six {
    padding: unset;
  }

  .seven {
    padding: unset;
  }

  .eight {
    padding: unset;
  }

  .ten {
    padding: unset;
    margin-top: -20px;
  }

  .eleven {
    padding: unset;
  }

  .twelve {
    padding: unset;
  }

  .thirteen {
    padding: unset;
  }

  .fourteen {
    padding: unset;
  }

  .one img {
    width: 300px !important;
    height: 300px !important;
  }

  .second img {
    width: 300px !important;
    height: 300px !important;
  }

  .third img {
    width: 300px !important;
    height: 300px !important;
  }

  .fourthimg {
    width: 300px !important;
    height: 300px !important;
  }

  .fifth img {
    width: 300px !important;
    height: 300px !important;
  }

  .six img {
    width: 300px !important;
    height: 300px !important;
  }

  .sevenimg {
    width: 300px !important;
    height: 300px !important;
  }

  .eight img {
    padding: unset;
  }

  .ten img {
    width: 300px !important;
    height: 300px !important;
  }

  .eleven img {
    width: 300px !important;
    height: 300px !important;
  }

  .twelve img {
    width: 300px !important;
    height: 300px !important;
  }

  .thirteen img {
    width: 300px !important;
    height: 300px !important;
  }

  .fourteen img {
    width: 300px !important;
    height: 300px !important;
  }

  .video1 {
    padding: unset;
  }

  .video2 {
    padding: unset;
  }
}