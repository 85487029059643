@import url("https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap");

.carousel {
  margin: auto;
  padding: 124px 0px;
}

.image_container {
  width: unset !important;
  display: flex;
  justify-content: space-between;
  background-color: white !important;
}

.para {
  text-align: justify;
  text-justify: inter-word;
  width: 50%;
  font-size: 12px;
}

.image {
  height: 500px;
  max-width: 95%;
  object-fit: contain;
}

@keyframes text {
  from {
    transform: translateY(20px);
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .image {
    height: 400px;
  }

  .carousel {
    margin: auto;
    padding: 0px;
    height: 80vh;
    width: 100vw;
  }

  .image_container {
    margin: 40px !important;
  }

  Slider {
    height: 70vh !important;
  }

  .flex {
    display: unset;
  }

  .para {
    width: 100%;
  }

  .arrows {
    margin: 0px 42px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .image {
    height: 800px;
    padding: 40px;
  }
}

@media (min-width: 1600px) {
  .image {
    height: 50vh !important;
  }
}